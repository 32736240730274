import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppJob } from '../../../../../../_base-shared/models/App/AppJob';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { ReportService } from '../report.service';

@Component({
  selector:    'app-data-insigts-report-list',
  templateUrl: './data-insights-report-list.component.html',
  styles:      [],
})
export class DataInsightsReportListComponent implements OnInit {
  public authUser: User;
  public appJobs: Array<AppJob> = [];
  public isLoadingJobs          = 0;
  public generatingBreakageReport: boolean;
  public isLoading = 0;


  public displayedColumns: string[] = [
    'name',
    'description',
    'actions',
  ];

  public reportOptions: Array<any> = [
    {
      name:        'Creditors Export',
      description: 'Generate Creditors Export Report',
      action:      this.genCreditorsReport.bind(this),
      disabled:    false,
    },
    {
      name:        'Wallets Export',
      description: 'Generate Wallets Export Report',
      action:      this.genWalletsReport.bind(this),
      disabled:    false,
    }
  ];

  constructor(private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private reportService: ReportService,
              public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.fetchQueuedJobs();
  }

  public fetchQueuedJobs(): void {
    this.isLoadingJobs++;
    this.reportService.indexQueuedExports({queue: 'export', 'with[]': 'user'}).pipe(finalize(() => this.isLoadingJobs--))
        .subscribe(
            result => {
              this.toastr.success(result.message ?? 'Queued jobs fetched successfully');
              this.appJobs = result.data;
            },
        );
  }

  public genCreditorsReport($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.isLoading++;
    this.reportService.generateCreditorsExportReport().pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
              this.toastr.success(result.message);
              // const fileName = 'payment_received_report_' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
              // saveAs(res.body, fileName);
              //this.dialogRef.close(true);
            },
            err => console.error(err),
        );
  }

  public genWalletsReport($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.isLoading++;
    this.reportService.generateWalletsExportReport().pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
              this.toastr.success(result.message);
              // const fileName = 'payment_received_report_' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';
              // saveAs(res.body, fileName);
              //this.dialogRef.close(true);
            },
            err => console.error(err),
        );
  }
}
