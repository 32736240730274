import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Subscription } from 'rxjs';
import { DistributionBatchService } from '../../distribution-batch.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../../case/case.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { ToastrService } from 'ngx-toastr';
import { DistributionService } from '../../distribution.service';

export interface CaseDetail {
    id: number;
    case_id: number;
    amount: number;
    amount_paid: number;
    term_date: string;
    type: string;
    name: string;
    date_paid: string;
    amount_distributed: number;
    distributed_at: string | null;
    distribution_status: string;
}

@Component({
    selector: 'app-distribution-model-info',
    templateUrl: './distribution-model-info.component.html',
    styles:      [
    `
    .dialog-container {
  max-height: 90vh; /* Set the max height relative to the viewport height */
  overflow-y: auto;
  padding: 20px;
}

.mat-table {
  width: 100%;
  margin: 20px 0;
}

.mat-header-cell, .mat-cell {
  padding: 16px;
  text-align: left;
  vertical-align: middle;
}

.mat-elevation-z3 {
  padding: 20px;
}

.mat-card-content {
  padding: 0;
}

.mat-paginator {
  margin: 0; 
}

.no-rows-message {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: grey;
}

.filter-section {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Space between items */
  align-items: center;
}

.filter-field {
  flex: 1 1 200px; /* Flex-grow, flex-shrink, min-width */
  min-width: 200px; /* Ensure it shrinks properly */
}

.button-group {
  margin-left: auto; 
  display: flex;
  gap: 8px;
}

.search-and-pagination-container {
  display: flex;
  align-items: center; 
  gap: 1rem; 
  margin-top: 1rem; 
}

button[mat-raised-button] {
  margin-right: 5px; 
}

button.blue-button {
  background-color: blue; 
  color: white; 
}

.mat-form-field {
  width: 100%; 
}

    `,
  ],
})

export class ModelInfoComponent implements OnInit {
  private subscriptions: Array<Subscription> = [];
  public requestFilters: any;
  public isLoading = 0;
  public displayedColumns: string[] = ['select','ref_number', 'amount_to_distribute', 'amount_distributed', 'term_date', 'type', 'name', 'date_paid', 'distributed_at', 'distribution_status'];
  public dataSource = new MatTableDataSource<CaseDetail>();
  public title: string = 'All payments';
  public searchFilter: UntypedFormControl = new UntypedFormControl(null);
  public defaultPaginatorConfig: MatPaginator;
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public startDate: Date;
  public endDate: Date;
  public filterField: string;
  public filterCaseId: string; // addedtoc ontinue
  public displayedFilterColumns: string[] = ['term_date', 'date_paid', 'distributed_at'];
  public cases: Array<Case> = [];
  public selection = new SelectionModel(true, []);
  public pageSelected: boolean;
  public serverResponse: LaravelResourceResponse;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public casesFilter: UntypedFormControl             = new UntypedFormControl(null);

  constructor(
      public dialogRef: MatDialogRef<ModelInfoComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private distributionBatchService: DistributionBatchService,
      private http: HttpClient,
      private caseService: CaseService,
      private translate: TranslateService,
      private distributionService: DistributionService,
      private toastr: ToastrService,
  ) {}

  ngOnInit() {
      console.log(this.data);
      
      this.defaultSort = { direction: 'desc', active: 'created_at' };

      if (this.data.opt == 2){
          this.title = 'Distributed payments';
          this.displayedColumns = this.displayedColumns.filter(column => column !== 'select');
      }else if (this.data.opt == 3){
          this.title = 'Due payments';
          this.displayedColumns = this.displayedColumns.filter(column => column !== 'select');
      }else if (this.data.opt == 4){
        this.title = 'On-account payments';
        this.displayedColumns = this.displayedColumns.filter(column => column !== 'select');
      } 

      this.requestFilters = {
          wallet_id: this.data.element.id,
          option: this.data.opt,
      };

      this.fetchDistributionTerms();
      this.fetchCases();
      this.subscribeToFilterChanges();
      
      
  }

  ngAfterViewInit() {
    this.defaultPaginatorConfig = this.paginator;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onCloseClick(): void {
      this.dialogRef.close();
  }

  private fetchCases() {
    this.caseService.index({has_distribution: 1}).pipe()
      .subscribe(next => {
        this.cases = next.data;
      });
  }

  private fetchDistributionTerms(): void {
      this.isLoading++;
      this.subscriptions.push(
          this.distributionBatchService.getDistributionTerms(this.requestFilters)
              .pipe(finalize(() => this.isLoading--))
              .subscribe(
                  result => {
                      console.log(result);
                      const caseDetails = Object.values(result);
                      this.dataSource.data = caseDetails;
                  },
                  err => console.error(err),
              ),
      );
  }

  private subscribeToFilterChanges() {
      this.subscriptions.push(
          this.searchFilter.valueChanges.pipe(
              debounceTime(300),
              distinctUntilChanged(),
          ).subscribe((batchStatus: any) => {
              this.searchChanged(batchStatus);
          }),
      );
      this.subscriptions.push(
        this.casesFilter.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged(),
        ).subscribe((casesIds: Array<number>) => {
          this.selectedFormCasesChanged(casesIds);
        }),
      );
  }

  public searchChanged(keyword: string): void {
      this.requestFilters.search = keyword;
      this.handleFilters();
  }

  public selectedFormCasesChanged(casesIds: Array<number>): void {
    console.log(casesIds);
    this.requestFilters.cases = casesIds;
    //this.handleFilters();
  }

  public handleFilters(): void {
      this.resetPagination();
      this.resetSort();
      this.fetchDistributionTerms();
  }

  private resetPagination(): void {
      this.dataSource.paginator = this.defaultPaginatorConfig;
  }

  private resetSort(): void {
      this.requestFilters.sort_by = this.defaultSort.active;
      this.requestFilters.order = this.defaultSort.direction;
  }

  public applyFilter(): void {
    /*const filterParams: any = {
        wallet_id: this.data.element.id,
        option: this.data.opt,
        start_date: this.startDate ? this.startDate.toISOString().split('T')[0] : null,
        end_date: this.endDate ? this.endDate.toISOString().split('T')[0] : null,
        filter_field: this.filterField,
    };*/

    this.requestFilters.start_date = this.startDate ? this.startDate.toISOString().split('T')[0] : null;
    this.requestFilters.end_date = this.endDate ? this.endDate.toISOString().split('T')[0] : null;
    this.requestFilters.filter_field = this.filterField;

    this.handleFilters();
    /*this.isLoading++;
    this.subscriptions.push(
        this.http.post('/api/distribution/filter', filterParams)
            .pipe(finalize(() => this.isLoading--))
            .subscribe(
                (result: any) => {
                    const caseDetails = Object.values(result);
                    this.dataSource.data = caseDetails;
                },
                (err: any) => console.error(err),
            ),
    );*/
  }

  public clearFilters(): void {
    this.startDate = null;
    this.endDate = null;
    this.filterField = null;
    this.searchFilter.setValue('');
    this.casesFilter.setValue('');

    //this.handleFilters();
  }

  public confirmDistributeTerms() {

    const termFilter = this.selection.selected.map(selectedCase => selectedCase.name);

    let selectedTermText;
    if (this.pageSelected) {
      selectedTermText = '- all term';
    } else if (termFilter.length === 1) {
      selectedTermText = termFilter[0];
    } else if (termFilter.length === 2) {
      selectedTermText = `${termFilter[0]} and ${termFilter[1]}`;
    } else {
      const lastWallet = termFilter.pop();
      selectedTermText = `${termFilter.join(', ')}, and ${lastWallet}`;
    }

    Swal.fire({
      title: this.translate.instant('SHARED.warning'),
      text: `Are you sure that you want to distribute the term: ${selectedTermText}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translate.instant('SHARED.no'),
      confirmButtonText: this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.distributeTerms(); // this.caseListFilter
      } else {
        this.clearSelection();
      }
    });
  }

  public distributeTerms() { //caseListFilter: CaseListFilter
  
    let term_filter = [];
    this.selection.selected.forEach(selectedCase => term_filter.push(selectedCase.id));
    
    if (this.pageSelected) {
      term_filter = [0];
    }
    
    //this.isAssigningBatch = true;
    this.subscriptions.push(
      this.distributionService.distributeTerms({ filters: term_filter })
        //.pipe(finalize(() => this.isAssigningBatch = false))
        .subscribe(
          result => {
            this.toastr.success(
            this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.success'));
            this.clearSelection();
            this.fetchDistributionTerms();
          },
          err => {
            //this.toastr.error(
            //  this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.error'));
            this.toastr.error('Term cannot be distributed');
            this.serverResponse = err.error;
          },
        ),
    );
  }

  // Select All
  public toggleRow(event: MatCheckboxChange, dataSource: CaseDetail) {
    this.selection.toggle(dataSource);
    if (!event.checked) {
      this.pageSelected = false;
      this.patchSelectAllFilter(0);
    }
  }

  public allRowsSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public togglePageSelect($event) {
    this.pageSelected = $event.checked;
    if (!this.pageSelected) {
      this.patchSelectAllFilter(0);
    }else{
      this.patchSelectAllFilter(1);
    }
    if (this.allRowsSelected()) {
      this.dataSource.data.forEach(wallet => this.selection.deselect(wallet));
    } else {
      this.dataSource.data.forEach(wallet => this.selection.select(wallet));
    }
  }

  public globalSelectAll($event) {
    $event.preventDefault();
    this.patchSelectAllFilter(1);
  }

  public globalClearSelectAll($event) {
    $event.preventDefault();
    this.clearSelection();
  }

  //end select all

  private patchSelectAllFilter(state: boolean | 0 | 1) {
    if (this.requestFilters) {
      this.requestFilters.select_all = state;
    } else {
      console.log('Filter component not ready for form pControl patching');
    }
  }

  private clearSelection() {
    this.selection.clear();
    //delete this.requestFilters.cases;
    this.pageSelected = false;
  }

}
