import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseDepartmentAssignment} from '../../../../../../../../_base-shared/models/Department/CaseDepartmentAssignment';
import { DepartmentCategory } from '../../../../../../../../_base-shared/models/Department/DepartmentCategory';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { DepartmentService } from '../../../../department/department.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector:    'app-case-department-user-editor',
  templateUrl: './case-department-user-editor.component.html',
  styles:      [],
})
export class CaseDepartmentUserEditorComponent implements OnInit {
  @Input() public case: Case;
  @Output() caseDepartmentsUpdated: EventEmitter<Array<CaseDepartmentAssignment>> = new EventEmitter<Array<CaseDepartmentAssignment>>();

  public form: UntypedFormGroup;
  public formActive: boolean;
  public isEditing = false;
  public isLoading = 0;
  public isSubmitting: boolean;
  public departmentCategories: Array<DepartmentCategory>;
  public caseDepartmentAssignments: Array<CaseDepartmentAssignment>;
  public authUser: User;
  public filteredDepartmentCategories: Array<DepartmentCategory>;
  public allowedUsers = [747150, 751524, 751600, 27, 752389, 752733];

  constructor(private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private departmentService: DepartmentService) {
  }

  ngOnInit(): void {
    this.fetchCategorizedDepartments();
  }

  private buildForm(caseId, caseDepartmentAssignments: Array<CaseDepartmentAssignment>): void {
    this.formActive = false;
    this.form       = this.fb.group({});

    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      this.filteredDepartmentCategories = this.departmentCategories;
      // if (this.case.product.slug !== 'unified') {
      //   this.filteredDepartmentCategories = this.departmentCategories.filter(departmentCategory => departmentCategory.type !== 'unifye');
      // }
      this.filteredDepartmentCategories.forEach(departmentCategory => {
        departmentCategory.departments.forEach(department => {
          const assignedUserId = caseDepartmentAssignments.filter(caseDepartmentAssignment => {
            return caseDepartmentAssignment.case_id === caseId &&
              caseDepartmentAssignment.department_id === department.id;
          }).map(caseAssignment => caseAssignment.user_id)[0];
          this.form.addControl(
            department.type,
            this.fb.control({value: assignedUserId, disabled: true}),
          );
        });
      });
      setTimeout(() => this.formActive = true, 0);
    });
    if (this.case.original_packager_id !== this.case.packager_id) {
      this.form.get('legal-advisor').disable({onlySelf: true});
    }
  }

  public isElementRestricted(){
    const enabledRoles = environment.ALLOW_CASES_ROLES_IDS;
    const enabledStatusId = environment.BLOCK_CASES_STATUS_IDS;
    const enabledDepartmentsId = environment.ALLOW_CASES_DEPARTMENTS_IDS;

    const isDepartmentAllowed = this.authUser.departments.some(department => 
      enabledDepartmentsId.includes(department.id)
    );
    
    if ( 
      !enabledStatusId.includes(this.case.status_id) || 
       enabledRoles.includes(this.authUser.role_id) ||
       isDepartmentAllowed
    ){
      return true;
    }
    return false;
  }

  public toggleEditMode(state: boolean): void {

    const enabledRoles = environment.ALLOW_CASES_ROLES_IDS;
    const enabledStatusId = environment.BLOCK_CASES_STATUS_IDS;
    const enabledDepartmentsId = environment.ALLOW_CASES_DEPARTMENTS_IDS;

    const isDepartmentAllowed = this.authUser.departments.some(department => 
      enabledDepartmentsId.includes(department.id)
    );

    this.isEditing = state;
    if (this.isEditing){
      if ( 
        !enabledStatusId.includes(this.case.status_id) || 
         enabledRoles.includes(this.authUser.role_id) ||
         isDepartmentAllowed
      ){
        this.form.enable();
      }
    }else{
      this.form.disable();
    } 
  /*  if (this.case.original_packager_id !== this.case.packager_id) {
      this.form.get('legal-advisor').disable({onlySelf: true});
    }*/
  }

  public submitForm(form: UntypedFormGroup): void {
    if (form.invalid) {
      this.toastr.error('Invalid Configuration Form');
      return;
    }
    this.isSubmitting = true;
    this.departmentService.updateCaseDepartmentAssignments(this.case.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        this.caseDepartmentAssignments = result.data;
        this.caseDepartmentsUpdated.emit(this.caseDepartmentAssignments);
        this.isEditing = false;
        this.buildForm(this.case.id, this.caseDepartmentAssignments);
      });
  }

  private fetchCategorizedDepartments(): void {
    this.isLoading++;
    this.departmentService.categoryIndex(['departments.users'])
      .pipe(finalize(() => this.isLoading--)).subscribe(result => {result.data.forEach(category => {
        category.departments = category.departments.filter(department => department.type !== 'financial-controller');
      });
      this.departmentCategories = result.data;
      this.fetchCaseDepartmentAssignments(this.case.id);
    });
  }

  private fetchCaseDepartmentAssignments(caseId: number): void {
    this.isLoading++;
    this.departmentService.getCaseDepartmentAssignments(caseId).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.caseDepartmentAssignments = result.data;
        this.buildForm(caseId, this.caseDepartmentAssignments);
      });
  }

  public canEdit(): boolean {
    return this.allowedUsers.includes(this.authUser.id);
  }
}
