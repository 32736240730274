<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h1>Reconciliation</h1>
    </mat-card-title>
    <div class="header-actions">
      <!--<a *ngIf="componentType === 'admin'" mat-raised-button color="primary"
         [routerLink]="['/distribution', 'batches', 'create']">
        + {{ 'SHARED.create' | translate }}
      </a>-->
      <!--<button *ngIf="authUser?.role_id === 5 && componentType === 'admin'" mat-raised-button class="ml-3"
              (click)="changeComponentType('distribution')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_provider_view' | translate }}
      </button>
      <button *ngIf="authUser?.role_id === 5 && componentType === 'distribution'" mat-raised-button class="ml-3"
              (click)="changeComponentType('admin')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_admin_view' | translate }}
      </button>-->
    </div>
  </mat-card-header>
  <mat-card-content>
    <!-- Filters -->
    
    <app-distribution-reconciliation-filters (filtersReady)="handleFiltersState($event)" (submitFilters)="handleSubmitFilters($event)">
    </app-distribution-reconciliation-filters>

    <div class="d-flex justify-content-between">
      
      <div class="ml-auto mr-auto d-flex flex-column">
        
      </div>
      
      <div class="text-right">
        <button mat-raised-button color="primary" (click)="applyFilters()">
          {{ 'SHARED.apply_filters' | translate }}
        </button>
      </div>
    </div>
  
    <mat-card class="mat-elevation-z3" style="margin-top: 20px;">
      <mat-card-content>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
        <!-- Table List -->
        <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Selection -->
          <ng-container matColumnDef="select">
            <!-- Select All On Page -->
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                            [checked]="selection.hasValue() && allRowsSelected()"
                            [indeterminate]="selection.hasValue() && !allRowsSelected()">
              </mat-checkbox>
            </th>
            <!-- Select Row -->
            <td mat-cell *matCellDef="let element">
              <mat-checkbox color="primary" [checked]="selection.isSelected(element)"
                            (change)="toggleRow($event, element)">
              </mat-checkbox>
            </td>
          </ng-container>
          

          <!-- Name -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              <!--{{ 'DISTRIBUTION.batch.model.name' | translate }}-->
              Wallet ID
            </th>
            <td mat-cell *matCellDef="let element">{{ element.batch_name }} </td>
          </ng-container>
          <!-- Term Name -->
          <ng-container matColumnDef="term_name">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              <!--{{ 'DISTRIBUTION.batch.list.table.cases_count' | translate }}-->
              Term Name
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>
          <!-- Case Id -->
          <ng-container matColumnDef="case_id">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              <!--{{ 'DISTRIBUTION.batch.model.status_updated_at' | translate }}-->
              Case ID
            </th>
            <td mat-cell *matCellDef="let element"><a [routerLink]="['/cases', element.case_id]">{{ element.ref_number }}</a></td>
          </ng-container>
          <!-- Term Date -->
          <ng-container matColumnDef="term_date">
            <th mat-header-cell *matHeaderCellDef>Term Date</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.term_date  | date }}
            </td>
          </ng-container>
          <!-- Amount -->
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              <!--{{ 'DISTRIBUTION.cases.list.table.phase_2_installment_amount' | translate }}-->
              Amount
            </th>
            <td mat-cell *matCellDef="let element">{{ element.amount  | currency: 'EUR'}}</td>
          </ng-container>
          <!-- Amount Paid -->
          <ng-container matColumnDef="amount_paid">
            <th mat-header-cell
                *matHeaderCellDef>Amount Paid</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.amount_paid | currency:'EUR' }}   
            </td>
          </ng-container>
          <!-- Date Paid -->
          <ng-container matColumnDef="date_paid">
            <th mat-header-cell
                *matHeaderCellDef>Date Paid</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.date_paid | date }}
            </td>
          </ng-container>
           <!-- Amount Distributed -->
           <ng-container matColumnDef="amount_distributed">
            <th mat-header-cell
                *matHeaderCellDef>Amount Distributed</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.amount_distributed | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Amount To Distribute -->
          <ng-container matColumnDef="amount_to_distribute">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              Amount To Distribute
            </th>
            <td mat-cell *matCellDef="let element" class="text-left">{{ element.amount_to_distribute | currency:'EUR' }}</td>
          </ng-container>
          <!-- Distributed At -->
          <ng-container matColumnDef="distributed_at">
            <th mat-header-cell
                *matHeaderCellDef>Distributed At</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.distributed_at | date }}
            </td>
          </ng-container>
          <!-- Distribution Status -->
          <ng-container matColumnDef="distribution_status">
            <th mat-header-cell *matHeaderCellDef>
              <!--{{ 'DISTRIBUTION.batch.detail.cases_table.fees_retained' | translate }}-->
              Distribution Status
            </th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.distribution_status }}
            </td>
          </ng-container>
          <!-- Hold Distribution -->
          <ng-container matColumnDef="hold_distribution">
            <th mat-header-cell *matHeaderCellDef>
              On Account
            </th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.hold_distribution === 1 ? 'Yes' : 'No' }}
            </td>
          </ng-container>
          <!-- Disbursed -->
          <ng-container matColumnDef="disbursed">
            <th mat-header-cell *matHeaderCellDef>
              Disbursed
            </th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.disbursed === 1 ? 'Yes' : 'No' }}
            </td>
          </ng-container>
           <!-- Disbursed At -->
           <ng-container matColumnDef="disbursed_at">
            <th mat-header-cell
                *matHeaderCellDef>Disbursed At</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.disbursed_at | date }}
            </td>
          </ng-container>
          <!-- Progress -->
          <ng-container matColumnDef="progress_bar">
            <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.amount" | translate }}</th>
            <td mat-cell *matCellDef="let element" style="padding-right: 10px;">
              <!--<small class="font-weight-lighter">{{element.amount_paid | number: '1.2-2'}}€
                / {{element.amount | number: '1.2-2'}}€ </small>-->
              <!--<span class="font-weight-bold">- {{element.amount - element.amount_paid | number: '1.2-2'}}€</span>-->
              <div class="progress" style="max-width: 120px; margin-right: 10px;">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercent(element.total_current_funded_amount, element.total_batch_contract_amount)  + '%'}"
                     [attr.aria-valuenow]="getPercent(element.total_current_funded_amount, element.total_batch_contract_amount)">
                     <!--[attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
                     {{ getPercent(element.total_current_funded_amount, element.total_batch_contract_amount) }}%-->
                </div>
              </div>
            </td>
          </ng-container>

          <!-- On account -->
          <ng-container matColumnDef="on-account">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              <!--{{ 'DISTRIBUTION.batch-list.pending_distribution' | translate }}-->
              On-account
            </th>
            <!--<td mat-cell *matCellDef="let element" class="text-left">{{ element.status }}</td>-->
            <!-- amount collected - amount distributed-->
            <td mat-cell *matCellDef="let element" class="text-left">{{ element.c_on_hold | currency:'EUR' }}</td>
          </ng-container>

          <!--<ng-container matColumnDef="progress_bar">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'DISTRIBUTION.batch.detail.cases_table.progress' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <div class="d-flex justify-content-center mt-3">
                <mat-progress-bar class="cash-hurdle-progress" mode="determinate"
                                  [matTooltip]="(element.total_distributed_amount | currency:'EUR') + ' / ' +
                                   (element.total_cash_hurdle_amount | currency:'EUR')"
                                  [value]="element.total_distributed_amount / (element.total_cash_hurdle_amount / 100)">
                </mat-progress-bar>
                <mat-progress-bar *ngIf="componentType === 'admin'" class="fees-retained-progress" mode="determinate"
                                  color="accent" [matTooltip]="(element.total_fees_retained | currency:'EUR') + ' / ' +
                                   (element.total_fees_obtainable | currency:'EUR')"
                                  [value]="element.total_fees_retained / (element.total_fees_obtainable / 100)">
                </mat-progress-bar>
                <mat-progress-bar *ngIf="componentType === 'distribution'" class="fees-retained-progress"
                                  mode="determinate" color="accent"
                                  [matTooltip]="(element.total_provider_fees_retained | currency:'EUR') + ' / ' +
                                   (element.total_provider_fees_obtainable | currency:'EUR')"
                                  [value]="element.total_provider_fees_retained / (element.total_provider_fees_obtainable / 100)">
                </mat-progress-bar>
              </div>
            </td>
          </ng-container>-->
          
          <!-- Actions -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div class="pt-2 pb-2">
                <!--<a mat-stroked-button [routerLink]="[element.id, 'cases']">
                  {{ 'SHARED.view' | translate }}
                </a>
                <a *ngIf="componentType === 'admin'" class="ml-3" mat-stroked-button
                   [routerLink]="[element.id, 'edit']">
                  {{ 'SHARED.edit' | translate }}
                </a>
                <a class="ml-2" (click)="modelDetails(element)" style="color: #04abe4;">
                  {{"CASE_CREDITOR.info.creditor_info" | translate}}
                </a>-->
                
                



                <a mat-raised-button color="primary"
                        [matMenuTriggerFor]="menuSecured">Actions</a>
                <mat-menu #menuSecured="matMenu">
                  <div>
                    <button mat-menu-item
                    (click)="modelDetails(element,1)">
                      Show all payments
                    </button>
                  </div>
                  <div>
                    <button mat-menu-item
                    (click)="modelDetails(element,2)">
                      Show only distributed
                    </button>
                  </div>
                  <div>
                    <button mat-menu-item
                    (click)="modelDetails(element,3)">
                      Show only due
                    </button>
                  </div>
                  <div>
                    <button mat-menu-item
                    (click)="modelDetails(element,4)">
                      Show on-account
                    </button>
                  </div>
                 

                </mat-menu>

















              </div>
            </td>
          </ng-container>
        </mat-table>
        <!-- No Data -->
        <div *ngIf="isLoading < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
          <h3
            class="text-center">{{ 'SHARED.no_data' | translate:{ models: 'DISTRIBUTION.batch.model_name.plural' | translate } }}</h3>
        </div>
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <button mat-raised-button color="primary" class="ml-3" (click)="confirmDisburseTerms()"
                  [disabled]="selection.selected.length === 0">
            Disburse
        </button>
        <button *ngIf="authUser.role_id === 5 || authUser.role_id === 7"
          mat-raised-button color="primary" class="ml-2" (click)="exportRecords()" type="button"
          [disabled]="selection.selected.length === 0">
            {{ 'PAYMENTS.list.export.button-name' | translate }}
        </button>
        <div class="footer-actions ml-3"></div>
        <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                       [pageSizeOptions]="[5, 10, 20]" [pageIndex]="paginatorConfig.pageIndex"
                       (page)="paginatorChange($event)">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>
  </mat-card-content>
</mat-card>
