import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { DistributionBatch } from '../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { DistributionProvider } from '../../../../../../_base-shared/models/Distribution/DistributionProvider';
import { User } from '../../../../../../_base-shared/models/User/User';
import {
  DistributionAmountCalculatorService
} from '../distribution-amount-calculator.service';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseService } from '../../case/case.service';
import { DistributionBatchService } from '../distribution-batch.service';
import { DistributionProviderService } from '../distribution-provider.service';
import { DistributionService } from '../distribution.service';
import { CaseListFilter } from '../../../../../../_base-shared/models/Case/CaseListFilter';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import Swal from 'sweetalert2';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';
import { CaseDistribution } from 'projects/_base-shared/models/Distribution/CaseDistribution';
// change to distributionListFilter?
import { PaymentListFilter } from '../../../../../../_base-shared/models/Payment/PaymentListFilter';
//import { MatPaginator } from '@angular/material/paginator';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

import { DistributionReconciliationFiltersComponent } from './distribution-reconciliation-filters/distribution-reconciliation-filters.component';

export interface TermDetail {
  id: number;
  case_id: number;
  amount: number;
  amount_paid: number;
  term_date: string;
  type: string;
  name: string;
  date_paid: string;
  amount_distributed: number;
  distributed_at: string | null;
  distribution_status: string;
}

@Component({
  selector:    'app-distribution-reconciliation',
  templateUrl: './distribution-reconciliation.component.html',
  styleUrls:   ['./distribution-reconciliation-component.scss']
})
export class DistributionReconciliationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // Wrappper
  @ViewChild(DistributionReconciliationFiltersComponent) DistributionModelFiltersComponent: DistributionReconciliationFiltersComponent;

  public authUser: User;
  public componentType: 'distribution' | 'admin';
  public filtersReady = false;
  public requestFilters: any;
  public form: UntypedFormGroup;
  public batches: MatTableDataSource<DistributionBatch> = new MatTableDataSource<DistributionBatch>([]);
  public dataSource = new MatTableDataSource<TermDetail>();
  public distributionProviders: Array<DistributionProvider> = [];
  public isLoading = 0;
  public serverResponse: LaravelResourceResponse;
  public displayedColumns: Array<string>;
  public selection = new SelectionModel(true, []);
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  //public defaultPaginatorConfig: MatPaginator;
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: 'created_at' };
  public totalResults: number;
  public totalPages: number;
  public pageSelected: boolean;
  public isAssigningBatch: boolean;

  //delet once test are done
  public securedVerifyDisabled: boolean = false;
  public securedResendDisabled: boolean = false;
  public securedRecoveryDisabled: boolean = false;
  public securedProposalAccordDisabled: boolean = false;
  public selectionSecured: boolean = false;
  //public securedResendDisabled: boolean = false;

  // Filters
  public batchStatusOptions: Array<AppSelectOption> = [];
  public caseListFilter: CaseListFilter; ////? maybe replace

  private subscriptions: Array<Subscription> = [];

  //MODEL TEMPORAL FOR MERGING?

  public totalCollectedAmt: number = 0;
  public totalDistributedAmt: number = 0;
  public totalOnHold: number = 0;
  //public activeClients: number = 0;
  //public distributionBillableAmount: number = 0;
  //public distributionAmountBilled: number = 0;

  //
  public paymentListFilter: PaymentListFilter;
  
  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private translate: TranslateService,
              private toastr: ToastrService,
              private globalEventService: MainGlobalEventService,
              private caseService: CaseService,
              private distributionService: DistributionService,
              private distributionBatchService: DistributionBatchService,
              private distributionProviderService: DistributionProviderService) {
    //super();
  }

  ngOnInit(): void {
    this.defaultPaginatorConfig = { pageIndex: 0, pageSize: 20, length: 1 };
    this.defaultSort = { direction: 'desc', active: 'created_at' };
    this.paginatorConfig = this.defaultPaginatorConfig;
    this.requestFilters = this.getDefaultFilters();
    this.buildFilterOptions();
    this.fetchDistributionProviders();
    //this.buildForm();

    this.globalEventService.authUser$.subscribe(user => {
      if (user) {
        this.authUser = user;
        this.componentType = this.authUser.role.slug === 'distribution-provider' ? 'distribution' : 'admin';
        this.displayedColumns = this.getTableColumns(this.componentType);
        //this.fetchBatches();
        this.fetchDistributionTerms();
      }
    });
  }

  ngAfterViewInit() {
    //this.paginator.pageSize = 20;
    //this.defaultPaginatorConfig = this.paginator;
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private resetPagination(): void {
    this.paginatorConfig = this.defaultPaginatorConfig;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.requestFilters.page = this.paginatorConfig.pageIndex;
  }

  /*private resetPagination(): void {
    //this.dataSource.paginator = this.defaultPaginatorConfig;
  }*/

  private resetSort(): void {
    this.requestFilters.sort_by = this.defaultSort.active;
    this.requestFilters.order = this.defaultSort.direction;
  }

  private clearSelection() {
    this.selection.clear();
    //delete this.requestFilters.cases;
    this.pageSelected = false;
  }

   //refactor


   private fetchDistributionTerms(): void {
    this.isLoading++;
    this.subscriptions.push(
        this.distributionBatchService.getConciliationTerms(this.requestFilters)
            .pipe(finalize(() => this.isLoading--))
            .subscribe(
                result => {
                    const termDetails = Object.values(result.data) as TermDetail[];
                    this.dataSource.data = termDetails;
                    this.updateTermsData(termDetails, result.meta);
                },
                err => console.error(err),
            ),
    );
}

   private fetchBatches(): void {
    const phaseTwoStatusIds = [414, 415];
  
    this.clearSelection();
    this.isLoading++;
  
    const fetchSubscriptions = this.distributionBatchService
      .index(this.requestFilters, [
        'distribution_provider',
        'case_distributions',
        'case_distributions.case',
        'case_distributions.case.unsecured_creditors',
        'case_distributions.case.creditors',
        'case_distributions.case.debt_payment_plan',
        'case_distributions.case.terms',
        'case_distributions.case.expense',
        'case_distributions.case.distribution_case_terms',
        'case_distributions.batch',
        'case_distributions.batch.distribution_terms',
      ])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          console.log(result);
          this.clearSelection();
          const dataWithTerms = result.data.reduce((acc, batch) => {
            return acc.concat(this.processBatch(batch));
          }, []);
          console.log(result.meta);
          this.updateBatchData(dataWithTerms, result.meta);
        },
        err => console.error(err)
      );
  
    this.subscriptions.push(fetchSubscriptions);
  }

  private processBatch(batch: any): any[] {
    // Check if case_distributions is defined and not empty
    if (!batch.case_distributions || batch.case_distributions.length === 0) {
      return [];
    }
  
    const firstCaseDistribution = batch.case_distributions[0];
  
    console.log("first case distribution");
    console.log(firstCaseDistribution);
  
    // Check if the first case_distribution is defined
    if (!firstCaseDistribution) {
      return [];
    }
  
    // Map over the distribution_terms of the first case_distribution
    return firstCaseDistribution.batch.distribution_terms.map(dt => ({
      ...dt,
      batch_name: batch.name
    }));
  }

  //necessary??
  private updateBatchData(dataWithTerms: any[], meta: any): void {
    this.batches = new MatTableDataSource<any>(dataWithTerms);
    this.batches.sort = this.sort;
    this.batches.paginator = this.paginator;
    this.paginatorConfig.length = meta.total;
    this.totalResults = meta.total;
    this.totalPages = meta.last_page;
  }

  private updateTermsData(dataWithTerms: any[], meta: any): void {
    this.paginatorConfig.length = meta.total;
    this.paginatorConfig.pageSize = meta.per_page;
    this.paginatorConfig.pageIndex = meta.current_page-1;
    this.totalResults = meta.total;
    this.totalPages = meta.last_page;
  }

  //end-refactor

  private getTableColumns(type: 'distribution' | 'admin'): Array<string> {
    return [
      'select',
      'name',
      'term_name',
      'case_id',
      'term_date',
      //'amount',
      //'amount_paid', 
      'date_paid', 
      'amount_distributed', 
      'amount_to_distribute',
      'distributed_at',
      'distribution_status',
      'hold_distribution',
      'disbursed',
      'disbursed_at',
      
      //'average_term',
      //'progress_bar',
      //'actions',
    ];
  }

  public getPercent(paid, amount) {
    if (( paid / amount ) * 100) {
      return ( ( paid / amount ) * 100 ).toFixed(2);
    } else {
      return 0;
    }
  }

  private getDefaultFilters() {
    return {
      select_all:                0,
      statuses:                  null,
      distribution_provider_ids: null,
      displayDistributed: 1,
    };
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize = $event.pageSize;
    this.paginatorConfig.length = $event.length;

    this.requestFilters.page = this.paginatorConfig.pageIndex + 1;
    this.requestFilters.per_page = this.paginatorConfig.pageSize;
    this.fetchDistributionTerms();
  }

  public sortData(sort) {
    this.requestFilters.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.requestFilters.order = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchBatches();
  }

  // On filter change
  public handleFilters($event: any): void {
    // this.requestFilters = $event;
    console.log("FILTERS, handleFilters (event disabled)?-----------");
    console.log($event);
    this.resetPagination();
    this.resetSort();
    this.fetchBatches();
  }

  private buildForm() {
    this.form = this.fb.group({
      statuses:                  [null],
      distribution_provider_ids: [null],
    });
    this.subscribeToFilterChanges();
  }

  private buildFilterOptions() {
    this.batchStatusOptions = [
      { value: 'pending', label: 'Pending' },
      { value: 'submitted', label: 'Submitted' },
      { value: 'funded', label: 'Funded' },
      { value: 'rejected', label: 'Rejected' },
    ];
  }

  private fetchDistributionProviders() {
    this.isLoading++;
    this.distributionProviderService.index({ select_all: 1 }).pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.distributionProviders = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
      );
  }

  private subscribeToFilterChanges() {
    this.subscriptions.push(
      this.form.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(formValue => {
        this.requestFilters = { ...this.requestFilters, ...formValue };
        this.handleFilters(this.requestFilters);
      }),
    );
  }

  public changeComponentType(type: 'distribution' | 'admin') {
    this.componentType = type;
    this.displayedColumns = this.getTableColumns(type);
  }

  // Select All
  public toggleRow(event: MatCheckboxChange, dataSource: TermDetail) {
    this.selection.toggle(dataSource);
    if (!event.checked) {
      this.pageSelected = false;
      this.patchSelectAllFilter(0);
    }
  }

  public allRowsSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public togglePageSelect($event) {
    this.pageSelected = $event.checked;
    if (!this.pageSelected) {
      this.patchSelectAllFilter(0);
    }else{
      this.patchSelectAllFilter(1);
    }
    if (this.allRowsSelected()) {
      this.dataSource.data.forEach(wallet => this.selection.deselect(wallet));
    } else {
      this.dataSource.data.forEach(wallet => this.selection.select(wallet));
    }
  }

  public globalSelectAll($event) {
    $event.preventDefault();
    this.patchSelectAllFilter(1);
  }

  public globalClearSelectAll($event) {
    $event.preventDefault();
    this.clearSelection();
  }

  // End Select All

  private patchSelectAllFilter(state: boolean | 0 | 1) {
    if (this.requestFilters) {
      this.requestFilters.select_all = state;
    } else {
      console.log('Filter component not ready for form pControl patching');
    }
  }

  public confirmDisburseTerms() {
    const termFilter = this.selection.selected.map(selectedCase => selectedCase.name);

    let selectedTermText;
    if (this.pageSelected) {
      selectedTermText = '- all term';
    } else if (termFilter.length === 1) {
      selectedTermText = termFilter[0];
    } else if (termFilter.length === 2) {
      selectedTermText = `${termFilter[0]} and ${termFilter[1]}`;
    } else {
      const lastWallet = termFilter.pop();
      selectedTermText = `${termFilter.join(', ')}, and ${lastWallet}`;
    }

    Swal.fire({
      title: this.translate.instant('SHARED.warning'),
      text: `Are you sure that you want to distribute the term: ${selectedTermText}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translate.instant('SHARED.no'),
      confirmButtonText: this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.distributeTerms(); // this.caseListFilter
      } else {
        this.clearSelection();
      }
    });
  }

  public exportRecords() { //paymentListFilter: PaymentListFilter
    let term_filter = [];
    this.selection.selected.forEach(selectedTerm => term_filter.push(selectedTerm.id));

    if (this.pageSelected) {
      console.log("page is selected");
      //this.requestFilters.term_filter = [0];
    }else{
      this.requestFilters.term_filter = term_filter;
    }

    this.requestFilters.expected_count = this.requestFilters.select_all ?
      this.totalResults :
      term_filter.length;

    const requestFilter              = MainBaseApiService.convertFiltersForRequest(this.requestFilters, 'get');

    this.isLoading++;
    this.subscriptions.push(
      this.distributionService.exportTerms(requestFilter).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.toastr.success(result.message);
          this.clearSelection();
        },
        err => this.serverResponse = err.error
      )
    );
    
  }

  public distributeTerms() { //caseListFilter: CaseListFilter
  
    let term_filter = [];
    this.selection.selected.forEach(selectedCase => term_filter.push(selectedCase.id));
    
    //console.log(wallet_filter);

    if (this.pageSelected) {
      term_filter = [0];
    }
    
    
    this.isAssigningBatch = true;
    this.subscriptions.push(
      this.distributionService.disburseTerms({ filters: term_filter })
        .pipe(finalize(() => this.isAssigningBatch = false))
        .subscribe(
          result => {
            //console.log("-----");
            //console.log(result);
            //console.log("-----");
            this.toastr.success(
            this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.success'));
            this.clearSelection();
          },
          err => {
            //this.toastr.error(
            //  this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.error'));
            this.toastr.error('Term cannot be disbursed');
            this.serverResponse = err.error;
          },
        ),
    );
  }


  public distributeWallet33(caseListFilter: CaseListFilter) {
  
    this.caseListFilter.case_ids = [];
    console.log(this.selection);
    this.selection.selected.forEach(selectedCase => caseListFilter.case_ids.push(selectedCase.id));
    console.log(caseListFilter);
    
    caseListFilter.expected_count = caseListFilter.select_all ? this.totalResults : caseListFilter.case_ids.length;
    const requestFilter           = MainBaseApiService.convertFiltersForRequest(caseListFilter, 'post');

    this.isAssigningBatch = true;
    this.subscriptions.push(
      this.distributionService.assignToWallet(requestFilter)
        .pipe(finalize(() => this.isAssigningBatch = false))
        .subscribe(
          result => {
            //console.log("-----");
            //console.log(result);
            //console.log("-----");
            if (result.message === 'non-existant-wallet'){
              this.toastr.error(
                'Wallet: ' + result.data + ' not available'
              );
            }else{
            
              this.toastr.success(
                this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.success'));
              if (caseListFilter.select_all) {
                //this.resetTable();
                //this.fetchCases();
                return;
              }
              /*const localCases = this.batches.data;
              result.data.forEach(clientCase => {
                const localCaseIndex = localCases.findIndex(sCase => sCase.id === clientCase.id);
                if (localCaseIndex >= 0) {
                  localCases[localCaseIndex].distribution = clientCase.distribution;
                } else {
                  localCases.unshift(clientCase);
                }
              });
              this.cases = new MatTableDataSource<Case>(localCases);*/
              this.clearSelection();
            }
          },
          err => {
            this.toastr.error(
              this.translate.instant('DISTRIBUTION.cases.list.actions.assign_batch.result.error'));
            this.serverResponse = err.error;
          },
        ),
    );
    this.caseListFilter.cases = null;
  }

  //creditor: Creditor, 
  modelDetails(element,opt): void {
    console.log(element);
    /*this.dialog.open(ModelInfoComponent, {
      width: 'fit-content',
      data: { element, opt }
    })*/
  }

  ////////

  public handleFiltersState($event: boolean): void {
    this.filtersReady = $event;
    console.log("FILTERS READY, handleFiltersState");
  }

  public handleSubmitFilters($event: any): void {
    // this.requestFilters = $event;
    console.log("FILTERS, handleFilters2222222222 (event disabled)?-----------");
    console.log($event);

    this.requestFilters.statuses = $event.statuses;
    //this.requestFilters.end_date = $event.end_date;
    //this.requestFilters.start_date = $event.start_date;
    
    this.requestFilters.start_date = $event.start_date ? $event.start_date.toISOString().split('T')[0] : null;
    this.requestFilters.end_date = $event.end_date ? $event.end_date.toISOString().split('T')[0] : null;
    
    this.requestFilters.search = $event.search;

    this.requestFilters.wallets = $event.wallets;

    this.requestFilters.displayPending = $event.displayPending ? $event.displayPending : 0;

    this.requestFilters.displayOnHold = $event.displayOnHold ? $event.displayOnHold : 0;

    this.requestFilters.displayPaid = $event.displayPaid ? $event.displayPaid : 0;

    this.requestFilters.displayDistributed = $event.displayDistributed ? $event.displayDistributed : 0;

    this.requestFilters.displayDisbursed = $event.displayDisbursed ? $event.displayDisbursed : 0;

    this.requestFilters.cases = $event.cases;
    /*const checkbox = $event.target as HTMLInputElement;
    const isChecked = checkbox.checked;
    const checkboxId = checkbox.id;

    console.log(`Checkbox5555 ${checkboxId} is checked:`, isChecked);

    if (checkboxId == 'displayPending'){
      
    }*/
    
    this.resetPagination();
    this.resetSort();
    //this.fetchBatches();
  }

  public handleDistributionVisibilityState($event: boolean): void {
    console.log("FILTERS, I do not, handleDistributionVisibilityState");
    //this.showDistribution = $event;
    //this.fetchPayments();
  }

  /*public handleFilters($event: PaymentListFilter): void {
    const oldPaymentMethod = this.paymentListFilter?.payment_method;
    this.paymentListFilter = $event;
    if (oldPaymentMethod !== this.paymentListFilter.payment_method) {
      this.paymentMethodChanged(this.paymentListFilter.payment_method);
    }
    if (!this.initialFetchComplete) {
      this.applyFilters();
      this.initialFetchComplete = true;
    }
  }*/

  public handleWireTypeOfPayment(isPaymentMethodWire: boolean): void {
    // this.disableApplyBtn = isPaymentMethodWire ? true : false;
  }

  public applyFilters() {
    this.resetPagination();
    this.resetSort();
    console.log("APPLY FILTERS");
    console.log(this.requestFilters);
    this.fetchDistributionTerms();
  }
}
